<template>
  <div class="spinner-container">
<div class="lds-roller">
  <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
</div>
</div>
 <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
    watch: {
    $route: {
      // eslint-disable-next-line no-unused-vars
      handler (to, from) {
        document.title = to.meta.title +  ' | '  + 'الإهداء الذكي ' 
      },
      immediate: true
    },
    }
    }
</script>
